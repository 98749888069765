export const monthNames = [
	{ value: "January", text: "January", no: 1 },
	{ value: "February", text: "February", no: 2 },
	{ value: "March", text: "March", no: 3 },
	{ value: "April", text: "April", no: 4 },
	{ value: "May", text: "May", no: 5 },
	{ value: "June", text: "June", no: 6 },
	{ value: "July", text: "July", no: 7 },
	{ value: "August", text: "August", no: 8 },
	{ value: "September", text: "September", no: 9 },
	{ value: "October", text: "October", no: 10 },
	{ value: "November", text: "November", no: 11 },
	{ value: "December", text: "December", no: 12 },
];

export const labs = [
	{
		title: "AIDEMEC",
		path: "../aidemec",
		logo: "/ll_logos/AIDEMEC.png",
		image: "/ll_images/AIDEMEC.png",
		region: "Mediterranean",
		coordinates: [40.575_348, 15.882_535],
		products: ["barley", "olive", "wheat", "tomato", "beans"],
		description: "Combines AI and agronomic tools to detect crop stress early, helping Mediterranean farmers protect yields from climate challenges.",
	},
	{
		title: "CONCATLL",
		path: "../concatll",
		logo: "/ll_logos/CONCATLL.png",
		image: "/ll_images/CONCATLL.png",
		region: "Mediterranean",
		coordinates: [41.586_168, 1.514_272],
		products: ["apple", "olive", "wheat", "poultry", "fish"],
		description: "Develops data-driven strategies to improve the resilience of Catalonia's agriculture against droughts, soil degradation, and other climate impacts.",
	},
	{
		title: "EcoReadyMasuria",
		path: "../ecoreadymasuria",
		logo: "/ll_logos/EcoReadyMasuria.png",
		image: "/ll_images/EcoReadyMasuria.png",
		region: "Central Europe",
		coordinates: [53.619_53, 20.366_513],
		products: ["barley", "maize", "sunflower", "red clover", "milk"],
		description: "A Polish innovation hub supporting farmers with climate adaptation trials, data collection, and policy recommendations to enhance food security and biodiversity.",
	},
	{
		title: "EcoVita LL",
		path: "../ecovitall",
		logo: "/ll_logos/EcoVitaLL.png",
		image: "/ll_images/EcoVitaLL.png",
		region: "Central Europe",
		coordinates: [47.492_367, 19.044_356],
		products: ["vertival farm", "leafy greens"],
		description: "Investigates vertical farming as a sustainable and resilient solution to address climate and food security challenges.",
	},
	{
		title: "Esappin",
		path: "../esappin",
		logo: "/ll_logos/Esappin.png",
		image: "/ll_images/Esappin.png",
		region: "Western Europe",
		coordinates: [51.443_657, 7.657_856],
		products: ["barley", "oats", "raspberry", "rapeseed", "mushroom"],
		description: "A German collaboration integrating academia, industry, and policy to advance sustainable farming practices and food security in North Rhine-Westphalia.",
	},
	{
		title: "LivOrganic",
		path: "../livorganic",
		logo: "/ll_logos/LivOrganic.png",
		image: "/ll_images/LivOrganic.png",
		region: "Scandinavia",
		coordinates: [55.939_112_079_462_35, 12.490_630_314_430_565],
		products: ["barley", "potato"],
		description: "Builds on Denmark's organic farming expertise to co-develop climate-adaptive, biodiversity-friendly solutions for food security in Scandinavia and the Baltic region.",
	},
	{
		title: "LOFS",
		path: "../lofs",
		logo: "/ll_logos/LOFS.png",
		image: "/ll_images/LOFS.png",
		region: "Western Europe",
		coordinates: [47.710_385, 1.727_322],
		// coordinates: { cite1: [47.710_385, 1.727_322], cite2: [47.769_127, -0.327_92] },
		products: ["mushroom", "grass"],
		description: "Addresses climate and biodiversity challenges in France's Loire Valley through future scenario modeling and farmer-driven adaptive strategies.",
	},
	{
		title: "Probio",
		path: "../probio",
		logo: "/ll_logos/Probio.png",
		image: "/ll_images/Probio.png",
		region: "Central Europe",
		coordinates: [49.039_97, 16.864_949],
		products: ["apple", "olive", "wheat", "poultry", "fish"],
		description: "Harnesses 15+ years of organic farming experience to co-create solutions for biodiversity, ecosystem health, and sustainable agriculture.",
	},
	{
		title: "Seco Collab",
		path: "../secocollab",
		logo: "/ll_logos/SecoCollab.png",
		image: "/ll_images/SecoCollab.png",
		region: "Scandinavia",
		coordinates: [59.618_634_049_219_36, 16.540_738_729_629_07],
		products: ["vertical farm", "leafy greens"],
		description: "Focuses on vertical farming, and uses technology and data to promote sustainable, urban food production and climate-resilient consumption in Scandinavia and Europe.",
	},
	{
		title: "THALLA",
		path: "../thalla",
		logo: "/ll_logos/Thalla.png",
		image: "/ll_images/Thalla.png",
		region: "Mediterranean",
		coordinates: [38.533_333, 22.366_667],
		products: ["honey", "olive", "wheat", "tomato", "fish"],
		description: "Co-creates agroecological practices in Greece, tackling food security, biodiversity, and climate resilience through stakeholder collaboration.",
	},
];

export const europeanCountries = [
	// Change key from region to country code
	// Region 1: AT - CZ - DK - FI - HU - LT - PL - SE - SK
	// Region 2: BE - DE - FR - NL
	// Region 3: BG - ES - HR - IT - PT - RO
	{ value: "EU", text: "European Union", flag: "🇪🇺" },
	{ value: "AL", text: "Albania", flag: "🇦🇱" },
	{ value: "AT", text: "Austria", flag: "🇦🇹", region: "Region 1" },
	{ value: "BE", text: "Belgium", flag: "🇧🇪", region: "Region 2" },
	{ value: "BA", text: "Bosnia and Herzegovina", flag: "🇧🇦" },
	{ value: "BG", text: "Bulgaria", flag: "🇧🇬", region: "Region 3" },
	{ value: "HR", text: "Croatia", flag: "🇭🇷", region: "Region 3" },
	{ value: "CY", text: "Cyprus", flag: "🇨🇾" },
	{ value: "CZ", text: "Czech Republic", flag: "🇨🇿", region: "Region 1" },
	{ value: "DK", text: "Denmark", flag: "🇩🇰", region: "Region 1" },
	{ value: "EE", text: "Estonia", flag: "🇪🇪" },
	{ value: "FI", text: "Finland", flag: "🇫🇮", region: "Region 1" },
	{ value: "FR", text: "France", flag: "🇫🇷", region: "Region 2" },
	{ value: "DE", text: "Germany", flag: "🇩🇪", region: "Region 2" },
	{ value: "EL", text: "Greece", flag: "🇬🇷" },
	{ value: "HU", text: "Hungary", flag: "🇭🇺", region: "Region 1" },
	{ value: "IS", text: "Iceland", flag: "🇮🇸" },
	{ value: "IE", text: "Ireland", flag: "🇮🇪" },
	{ value: "IT", text: "Italy", flag: "🇮🇹", region: "Region 3" },
	{ value: "LV", text: "Latvia", flag: "🇱🇻" },
	{ value: "LI", text: "Liechtenstein", flag: "🇱🇮" },
	{ value: "LT", text: "Lithuania", flag: "🇱🇹", region: "Region 1" },
	{ value: "LU", text: "Luxembourg", flag: "🇱🇺" },
	{ value: "MT", text: "Malta", flag: "🇲🇹" },
	{ value: "MD", text: "Moldova", flag: "🇲🇩" },
	{ value: "ME", text: "Montenegro", flag: "🇲🇪" },
	{ value: "NL", text: "Netherlands", flag: "🇳🇱", region: "Region 2" },
	{ value: "MK", text: "North Macedonia", flag: "🇲🇰" },
	{ value: "NO", text: "Norway", flag: "🇳🇴" },
	{ value: "PL", text: "Poland", flag: "🇵🇱", region: "Region 1" },
	{ value: "PT", text: "Portugal", flag: "🇵🇹", region: "Region 3" },
	{ value: "RO", text: "Romania", flag: "🇷🇴", region: "Region 3" },
	{ value: "RS", text: "Serbia", flag: "🇷🇸" },
	{ value: "SK", text: "Slovakia", flag: "🇸🇰", region: "Region 1" },
	{ value: "SI", text: "Slovenia", flag: "🇸🇮" },
	{ value: "ES", text: "Spain", flag: "🇪🇸", region: "Region 3" },
	{ value: "SE", text: "Sweden", flag: "🇸🇪", region: "Region 1" },
	{ value: "CH", text: "Switzerland", flag: "🇨🇭" },
	{ value: "UA", text: "Ukraine", flag: "🇺🇦" },
	{ value: "UK", text: "United Kingdom", flag: "🇬🇧" },
];

export const products = [
//	{
//		value: "beef",
//		text: "Beef",
//		collections: ["carcass_prices", "live_animal_prices", "production"],
//		description: "No living labs currently working with beef",
//		image: "/product_images/beef.png",
//	},
//	{
//		value: "cereals",
//		text: "Cereals",
//		description: "AIDEMEC (barley, wheat), CONCATLL (wheat), EcoReadyMasuria (barley, maize), Esappin (barley, oats), LivOrganic (barley), Probio (wheat), THALLA (wheat)",
//		image: "/product_images/cereals.png",
//	},
//	{
//		value: "eggs_poultry",
//		text: "Eggs/Poultry",
//		collections: ["poultry_prices", "poultry_monthly_prices", "poultry_production", "egg_prices"],
//		description: "CONCATLL, Probio",
//		relevantLLs: ["CONCATLL", "Probio"],
//		image: "/product_images/poultry.jpg",
//	},
//	{
//		value: "fertiliser",
//		text: "Fertiliser",
//		description: "No living labs currently working with fertiliser",
//		image: "/product_images/fertiliser.png",
//	},
	//	{
	//		value: "fruit_vegetables",
	//		text: "Fruits & Vegetables",
	//	},
	// Fruits and Vegetables
	{ value: "abricots", text: "Abricots", image: "/product_images/abricots.png" },
	{ value: "apples", text: "Apples", image: "/product_images/apples.jpg" },
	{ value: "asparagus", text: "Asparagus", image: "/product_images/asparagus.jpg" },
	{ value: "avocados", text: "Avocados", image: "/product_images/avocados.jpg" },
	{ value: "beans", text: "Beans", image: "/product_images/beans.png" },
	{ value: "cabbages", text: "Cabbages", image: "/product_images/cabbages.jpg" },
	{ value: "carrots", text: "Carrots", image: "/product_images/carrots.jpg" },
	{ value: "cauliflowers", text: "Cauliflowers", image: "/product_images/cauliflowers.jpg" },
	{ value: "cherries", text: "Cherries", image: "/product_images/cherries.jpg" },
	{ value: "clementines", text: "Clementines", image: "/product_images/clementines.jpg" },
	{ value: "courgettes", text: "Courgettes", image: "/product_images/courgettes.jpg" },
	{ value: "cucumbers", text: "Cucumbers", image: "/product_images/cucumbers.jpg" },
	{ value: "egg plants, aubergines", text: "Egg plants", image: "/product_images/eggplants.jpg" },
	{ value: "garlic", text: "Garlic", image: "/product_images/garlic.jpg" },
	{ value: "kiwis", text: "Kiwis", image: "/product_images/kiwis.jpg" },
	{ value: "leeks", text: "Leeks", image: "/product_images/leeks.jpg" },
	{ value: "lemons", text: "Lemons", image: "/product_images/lemons.jpg" },
	{ value: "lettuces", text: "Lettuces", image: "/product_images/lettuces.jpg" },
	{ value: "mandarins", text: "Mandarins", image: "/product_images/mandarins.jpg" },
	{ value: "melons", text: "Melons", image: "/product_images/melons.jpg" },
	{ value: "mushrooms, cultivated", text: "Mushrooms", image: "/product_images/mushrooms.jpg" },
	{ value: "nectarines", text: "Nectarines", image: "/product_images/nectarines.jpg" },
	{ value: "onions", text: "Onions", image: "/product_images/onions.jpg" },
	{ value: "oranges", text: "Oranges", image: "/product_images/oranges.jpg" },
	{ value: "peaches", text: "Peaches", image: "/product_images/peaches.jpg" },
	{ value: "pears", text: "Pears", image: "/product_images/pears.jpg" },
	{ value: "peppers", text: "Peppers", image: "/product_images/peppers.jpg" },
	{ value: "plums", text: "Plums", image: "/product_images/plums.jpg" },
	{ value: "satsumas", text: "Satsumas", image: "/product_images/satsumas.jpg" },
	{ value: "strawberries", text: "Strawberries", image: "/product_images/strawberries.jpg" },
	{ value: "table grapes", text: "Grapes", image: "/product_images/grapes.jpg" },
	{ value: "tomatoes", text: "Tomatoes", image: "/product_images/tomatoes.jpg" },
	{ value: "water melons", text: "Watermelons", image: "/product_images/watermelons.jpg" },
	{
		value: "milk_dairy",
		text: "Milk/Dairy",
		description: "EcoReadyMasuria (milk)",
		image: "/product_images/milk_dairy.jpg",
	},
	{
		value: "oilseeds_protein_crops",
		text: "Oilseeds Protein/Crops",
		description: "EcoReadyMasuria (sunflower), Esappin (rapeseed)",
		image: "/product_images/oilseeds_protein.jpg",
	},
	{
		value: "olive_oil",
		text: "Olive Oil",
		description: "AIDEMEC, CONCATLL, Probio, THALLA",
		image: "/product_images/olive_oil.jpg",
	},
	{
		value: "organic",
		text: "Organic",
		description: "LivOrganic, Probio",
		image: "/product_images/organic.jpg",
	},
	{
		value: "pigmeat",
		text: "Pigmeat",
		collections: ["carcass_prices", "cuts_prices", "cuts_prices_monthly", "production"],
		description: "No living labs currently working with pigmeat",
		image: "/product_images/pigmeat.png",
	},
	{
		value: "rice",
		text: "Rice",
		collections: ["prices", "production"],
		description: "No living labs currently working with rice",
		image: "/product_images/rice.jpg",
	},
	{
		value: "sheep_goat_meat",
		text: "Sheep/Goat Meat",
		collections: ["meat_prices", "production"],
		description: "No living labs currently working with sheep/goat meat",
		image: "/product_images/sheep_goat_meat.png",
	},
	{
		value: "sugar",
		text: "Sugar",
		description: "No living labs currently working with sugar",
		image: "/product_images/sugar.jpg",
	},
	{
		value: "wine",
		text: "Wine",
		description: "No living labs currently working with wine",
		image: "/product_images/wine.jpg",
	},
];

export const years = [
	{ value: 2010, label: "2010" },
	{ value: 2011, label: "2011" },
	{ value: 2012, label: "2012" },
	{ value: 2013, label: "2013" },
	{ value: 2014, label: "2014" },
	{ value: 2015, label: "2015" },
	{ value: 2016, label: "2016" },
	{ value: 2017, label: "2017" },
	{ value: 2018, label: "2018" },
	{ value: 2019, label: "2019" },
	{ value: 2020, label: "2020" },
	{ value: 2021, label: "2021" },
	{ value: 2022, label: "2022" },
	{ value: 2023, label: "2023" },
	{ value: 2024, label: "2024" },
	{ value: 2025, label: "2025" },
];
